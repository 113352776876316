import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/user/index.js'), 'user/index.js')
  resolveStoreModules(require('../store/subscriptionType/index.js'), 'subscriptionType/index.js')
  resolveStoreModules(require('../store/search/index.js'), 'search/index.js')
  resolveStoreModules(require('../store/resumeWrite/index.js'), 'resumeWrite/index.js')
  resolveStoreModules(require('../store/prompt/index.js'), 'prompt/index.js')
  resolveStoreModules(require('../store/payType/index.js'), 'payType/index.js')
  resolveStoreModules(require('../store/occupation/index.js'), 'occupation/index.js')
  resolveStoreModules(require('../store/notification/index.js'), 'notification/index.js')
  resolveStoreModules(require('../store/me/index.js'), 'me/index.js')
  resolveStoreModules(require('../store/location/index.js'), 'location/index.js')
  resolveStoreModules(require('../store/leadgenPromotion/index.js'), 'leadgenPromotion/index.js')
  resolveStoreModules(require('../store/leadgen/index.js'), 'leadgen/index.js')
  resolveStoreModules(require('../store/jobTeaser/index.js'), 'jobTeaser/index.js')
  resolveStoreModules(require('../store/jobModal/index.js'), 'jobModal/index.js')
  resolveStoreModules(require('../store/jobApplication/index.js'), 'jobApplication/index.js')
  resolveStoreModules(require('../store/job/index.js'), 'job/index.js')
  resolveStoreModules(require('../store/global/index.js'), 'global/index.js')
  resolveStoreModules(require('../store/eventTracking/index.js'), 'eventTracking/index.js')
  resolveStoreModules(require('../store/common/index.js'), 'common/index.js')
  resolveStoreModules(require('../store/category/index.js'), 'category/index.js')
  resolveStoreModules(require('../store/brand/index.js'), 'brand/index.js')
  resolveStoreModules(require('../store/auth/index.js'), 'auth/index.js')
  resolveStoreModules(require('../store/attribute/index.js'), 'attribute/index.js')
  resolveStoreModules(require('../store/appInfo/index.js'), 'appInfo/index.js')
  resolveStoreModules(require('../store/apiMessage/index.js'), 'apiMessage/index.js')
  resolveStoreModules(require('../store/ads/index.js'), 'ads/index.js')
  resolveStoreModules(require('../store/ads/mutations.js'), 'ads/mutations.js')
  resolveStoreModules(require('../store/appInfo/actions.js'), 'appInfo/actions.js')
  resolveStoreModules(require('../store/appInfo/getters.js'), 'appInfo/getters.js')
  resolveStoreModules(require('../store/appInfo/mutations.js'), 'appInfo/mutations.js')
  resolveStoreModules(require('../store/attribute/actions.js'), 'attribute/actions.js')
  resolveStoreModules(require('../store/auth/actions.js'), 'auth/actions.js')
  resolveStoreModules(require('../store/auth/mutations.js'), 'auth/mutations.js')
  resolveStoreModules(require('../store/brand/actions.js'), 'brand/actions.js')
  resolveStoreModules(require('../store/brand/getters.js'), 'brand/getters.js')
  resolveStoreModules(require('../store/brand/mutations.js'), 'brand/mutations.js')
  resolveStoreModules(require('../store/category/actions.js'), 'category/actions.js')
  resolveStoreModules(require('../store/common/actions.js'), 'common/actions.js')
  resolveStoreModules(require('../store/common/mutations.js'), 'common/mutations.js')
  resolveStoreModules(require('../store/education/actions.js'), 'education/actions.js')
  resolveStoreModules(require('../store/eventTracking/actions.js'), 'eventTracking/actions.js')
  resolveStoreModules(require('../store/eventTracking/mutations.js'), 'eventTracking/mutations.js')
  resolveStoreModules(require('../store/job/actions.js'), 'job/actions.js')
  resolveStoreModules(require('../store/job/getters.js'), 'job/getters.js')
  resolveStoreModules(require('../store/job/mutations.js'), 'job/mutations.js')
  resolveStoreModules(require('../store/jobApplication/actions.js'), 'jobApplication/actions.js')
  resolveStoreModules(require('../store/jobApplication/getters.js'), 'jobApplication/getters.js')
  resolveStoreModules(require('../store/jobApplication/mutations.js'), 'jobApplication/mutations.js')
  resolveStoreModules(require('../store/jobModal/getters.js'), 'jobModal/getters.js')
  resolveStoreModules(require('../store/jobModal/mutations.js'), 'jobModal/mutations.js')
  resolveStoreModules(require('../store/jobTeaser/actions.js'), 'jobTeaser/actions.js')
  resolveStoreModules(require('../store/jobTeaser/getters.js'), 'jobTeaser/getters.js')
  resolveStoreModules(require('../store/jobTeaser/mutations.js'), 'jobTeaser/mutations.js')
  resolveStoreModules(require('../store/leadgen/mutations.js'), 'leadgen/mutations.js')
  resolveStoreModules(require('../store/leadgenPromotion/actions.js'), 'leadgenPromotion/actions.js')
  resolveStoreModules(require('../store/location/actions.js'), 'location/actions.js')
  resolveStoreModules(require('../store/log/actions.js'), 'log/actions.js')
  resolveStoreModules(require('../store/me/actions.js'), 'me/actions.js')
  resolveStoreModules(require('../store/me/getters.js'), 'me/getters.js')
  resolveStoreModules(require('../store/me/mutations.js'), 'me/mutations.js')
  resolveStoreModules(require('../store/notification/actions.js'), 'notification/actions.js')
  resolveStoreModules(require('../store/notification/mutations.js'), 'notification/mutations.js')
  resolveStoreModules(require('../store/occupation/actions.js'), 'occupation/actions.js')
  resolveStoreModules(require('../store/payType/actions.js'), 'payType/actions.js')
  resolveStoreModules(require('../store/payType/mutations.js'), 'payType/mutations.js')
  resolveStoreModules(require('../store/prompt/actions.js'), 'prompt/actions.js')
  resolveStoreModules(require('../store/prompt/mutations.js'), 'prompt/mutations.js')
  resolveStoreModules(require('../store/resumeWrite/mutations.js'), 'resumeWrite/mutations.js')
  resolveStoreModules(require('../store/search/actions.js'), 'search/actions.js')
  resolveStoreModules(require('../store/search/getters.js'), 'search/getters.js')
  resolveStoreModules(require('../store/search/mutations.js'), 'search/mutations.js')
  resolveStoreModules(require('../store/seekerEvents/actions.js'), 'seekerEvents/actions.js')
  resolveStoreModules(require('../store/subscriptionType/actions.js'), 'subscriptionType/actions.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/job/actions/job.js'), 'job/actions/job.js')
  resolveStoreModules(require('../store/job/getters/job.js'), 'job/getters/job.js')
  resolveStoreModules(require('../store/job/mutations/job.js'), 'job/mutations/job.js')
  resolveStoreModules(require('../store/jobApplication/mutations/apply.js'), 'jobApplication/mutations/apply.js')
  resolveStoreModules(require('../store/jobApplication/mutations/applyForm.js'), 'jobApplication/mutations/applyForm.js')
  resolveStoreModules(require('../store/jobApplication/mutations/customQuestions.js'), 'jobApplication/mutations/customQuestions.js')
  resolveStoreModules(require('../store/jobApplication/mutations/onboardingQuestions.js'), 'jobApplication/mutations/onboardingQuestions.js')
  resolveStoreModules(require('../store/jobApplication/mutations/signinModal.js'), 'jobApplication/mutations/signinModal.js')
  resolveStoreModules(require('../store/jobTeaser/mutations/teaser.js'), 'jobTeaser/mutations/teaser.js')
  resolveStoreModules(require('../store/me/actions/appliedJob.js'), 'me/actions/appliedJob.js')
  resolveStoreModules(require('../store/me/actions/applyPass.js'), 'me/actions/applyPass.js')
  resolveStoreModules(require('../store/me/actions/attribute.js'), 'me/actions/attribute.js')
  resolveStoreModules(require('../store/me/actions/coverLetter.js'), 'me/actions/coverLetter.js')
  resolveStoreModules(require('../store/me/actions/desiredOccupation.js'), 'me/actions/desiredOccupation.js')
  resolveStoreModules(require('../store/me/actions/discovery.js'), 'me/actions/discovery.js')
  resolveStoreModules(require('../store/me/actions/education.js'), 'me/actions/education.js')
  resolveStoreModules(require('../store/me/actions/jobEvents.js'), 'me/actions/jobEvents.js')
  resolveStoreModules(require('../store/me/actions/me.js'), 'me/actions/me.js')
  resolveStoreModules(require('../store/me/actions/onesignal.js'), 'me/actions/onesignal.js')
  resolveStoreModules(require('../store/me/actions/partner.js'), 'me/actions/partner.js')
  resolveStoreModules(require('../store/me/actions/profileTask.js'), 'me/actions/profileTask.js')
  resolveStoreModules(require('../store/me/actions/resume.js'), 'me/actions/resume.js')
  resolveStoreModules(require('../store/me/actions/savedJob.js'), 'me/actions/savedJob.js')
  resolveStoreModules(require('../store/me/actions/savedSearch.js'), 'me/actions/savedSearch.js')
  resolveStoreModules(require('../store/me/actions/scoring.js'), 'me/actions/scoring.js')
  resolveStoreModules(require('../store/me/actions/subscriptions.js'), 'me/actions/subscriptions.js')
  resolveStoreModules(require('../store/me/actions/workExperience.js'), 'me/actions/workExperience.js')
  resolveStoreModules(require('../store/me/actions/workLocation.js'), 'me/actions/workLocation.js')
  resolveStoreModules(require('../store/me/mutations/appliedJob.js'), 'me/mutations/appliedJob.js')
  resolveStoreModules(require('../store/me/mutations/attributeOption.js'), 'me/mutations/attributeOption.js')
  resolveStoreModules(require('../store/me/mutations/coverLetter.js'), 'me/mutations/coverLetter.js')
  resolveStoreModules(require('../store/me/mutations/desiredOccupation.js'), 'me/mutations/desiredOccupation.js')
  resolveStoreModules(require('../store/me/mutations/discovery.js'), 'me/mutations/discovery.js')
  resolveStoreModules(require('../store/me/mutations/education.js'), 'me/mutations/education.js')
  resolveStoreModules(require('../store/me/mutations/jobEvents.js'), 'me/mutations/jobEvents.js')
  resolveStoreModules(require('../store/me/mutations/profileTask.js'), 'me/mutations/profileTask.js')
  resolveStoreModules(require('../store/me/mutations/resume.js'), 'me/mutations/resume.js')
  resolveStoreModules(require('../store/me/mutations/resumeQualityFeedback.js'), 'me/mutations/resumeQualityFeedback.js')
  resolveStoreModules(require('../store/me/mutations/savedJob.js'), 'me/mutations/savedJob.js')
  resolveStoreModules(require('../store/me/mutations/savedSearch.js'), 'me/mutations/savedSearch.js')
  resolveStoreModules(require('../store/me/mutations/scoring.js'), 'me/mutations/scoring.js')
  resolveStoreModules(require('../store/me/mutations/user.js'), 'me/mutations/user.js')
  resolveStoreModules(require('../store/me/mutations/widgets.js'), 'me/mutations/widgets.js')
  resolveStoreModules(require('../store/me/mutations/workExperience.js'), 'me/mutations/workExperience.js')
  resolveStoreModules(require('../store/me/mutations/workLocation.js'), 'me/mutations/workLocation.js')
  resolveStoreModules(require('../store/payType/actions/salary.js'), 'payType/actions/salary.js')
  resolveStoreModules(require('../store/search/mutations/saveSearch.js'), 'search/mutations/saveSearch.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}

import { render, staticRenderFns } from "./discovery.vue?vue&type=template&id=5ad93b29&scoped=true&"
import script from "./discovery.vue?vue&type=script&lang=js&"
export * from "./discovery.vue?vue&type=script&lang=js&"
import style0 from "./discovery.vue?vue&type=style&index=0&id=5ad93b29&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad93b29",
  null
  
)

export default component.exports
/* eslint-disable */
import has from 'lodash/has';
import forOwn from 'lodash/forOwn';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isNaN from 'lodash/isNaN';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import pull from 'lodash/pull';

export const getTokenFromReq = (req) => {
    const hasHeaderCookie = has(req.headers, 'cookie');
    if (hasHeaderCookie) {
        const cookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${process.env.COOKIE_TOKEN_NAME}=`));

        if (cookie) {
            return cookie.split('=')[1];
        }
    }

    return '';
};

export const pushEvent = (Data) => {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api-gateway.careerone.com.au/logs/events', true);

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('c1key', 'HoCBXldx1l9ryZzfob3uo78MQQrTtOnQ2dyqDaYg');
    var data = {
        Data: Data
    };
    xhr.send(JSON.stringify(data));
}

export const strScape = (str) => {
    if (!str) return '';
    return str.replace(/'/g, "\\'");
};

export const imgResize = (url, w = 150, h = 150, q = 100) => {
    if (!url) { return ''; }
    return `${process.env.RESIZE_URL.replace('{width}', w).replace('{height}', h).replace('{quality}', q)}/${url}`;
};

export const slugify = (slug) => {
    return slug
        .trimStart()
        .trimEnd()
        .replace(/[!#$\%^&\*\(\)\:;\'"|\\\{\}\[\]<>,\.\?\/~`=\+]/g, '')
        .replace(/[_ ]/g, '-')
        .replace('@', '-at-')
        .replace(/[-]+/g, '-')
        .toLowerCase();
};

export const pay = (pay) => {
    return Math.round(pay);
};

export const payLabel = (pay) => {
    return `$${Math.round(pay)}`;
};

export function removeNulls(obj) {
    return function prune(current) {
        forOwn(current, function (value, key) {
            if (isUndefined(value) || isNull(value) || isNaN(value) ||
                (isString(value) && isEmpty(value)) ||
                (isObject(value) && isEmpty(prune(value)))) {

                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (isArray(current)) pull(current, undefined);

        return current;

    }(cloneDeep(obj));  // Do not modify the original object, create a clone instead
}

export function everyNowAndThen(everyMin = 30) {
    const times = []; // time array
    let tt = 0; // start time
    const ap = ['am', 'pm']; // AM-PM

    //loop to increment the time and push results in array
    for (let i=0;tt<24*60; i++) {
        const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        const mm = tt % 60; // getting minutes of the hour in 0-55 format
        times[i] = ('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + everyMin;
    }

    return times;
}

export function shuffleList(array) {
    // Fisher Yates
    let count = array.length, randomnumber, temp;
    while( count ){
        randomnumber = Math.random() * count-- | 0;
        temp = array[count];
        array[count] = array[randomnumber];
        array[randomnumber] = temp
    }
    return array;
}


export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function getImgUrl(img) {
    var images = require.context('../assets/', false, /\.svg$/)
    return images('./' + img + ".svg")
}

export function getDomainName() {
    return window.location.hostname;
}

export function numberWithComma(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getKeyValue(obj) {
    let keyValue = null;
    if (obj && obj.length) {
        keyValue = obj.map((item) => {
            let value = '';
            if (item.name) {
                value = item.name;
            }
            if (item.value) {
                value = item.value;
            }
            return {
                id: item.id,
                value: value,
            };
        });
    }
    return keyValue;
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function getComponent(parentComponent, componentName) {
    let component = null;
    while (parentComponent && !component) {
        if (parentComponent.$options.name === componentName) {
            component = parentComponent;
        }
        parentComponent = parentComponent.$parent;
    }
    return component;
}

export function convertToHtml(content) {
    return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function convertToUrl(url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'http://' + url;
    }

    return url;
}

export function loadScript(url, callback) {
    const script = document.createElement('script');
    script.setAttribute('src', url);
    document.head.appendChild(script);
    script.onload = () => {
        callback();
    };
}

export function loadStylesheet(url) {
    const style = document.createElement('link');
    style.setAttribute('rel', 'stylesheet');
    style.setAttribute('href', url);
    document.head.appendChild(style);
}

export function deepCloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}

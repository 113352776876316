export default {
    getApplyPass() {
        const url = 'v1/me/apply-pass';
        return this.$axios.$get(url);
    },

    getApplyPassListJob(context, data) {
        let url = 'v1/me/apply-pass/list-job';
        const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
        if (query) {
            url += `?${query}`;
        }
        return this.$axios.$get(url);
    },

    getApplyPassMatchingJobs(context, data) {
        let url = 'v1/me/apply-pass/match';
        const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
        if (query) {
            url += `?${query}`;
        }
        return this.$axios.$get(url);
    },
};


    import { mapState, mapGetters, mapMutations } from 'vuex';
    import LazyHydrate from 'vue-lazy-hydration';

    import HtmlHeader from '@/components/Partials/HtmlHeader';
    import AppHeader from '@/components/Partials/AppHeader';

    export default {
        name: 'Home',

        components: {
            LazyHydrate,
            AppHeader,
            HeaderNonLoggedIn: () => import(/* webpackChunkName: "HeaderNonLoggedIn" */'@/components/Partials/Headers/HeaderNonLoggedIn'),
            MobileMenu: () => import(/* webpackChunkName: "MobileMenu" */'@/components/Partials/MobileMenu'),
            AppFooter: () => import(/* webpackChunkName: "AppFooter" */'@/components/Partials/AppFooter'),
        },

        mixins: [HtmlHeader],

        computed: {
            ...mapState({
                $_isSidebarOpen: state => state.appInfo.isSidebarOpen,
                $_me: state => state.me,
                $_global: state => state.global,
            }),

            ...mapGetters('me', {
                $_defaultResume: 'defaultResume',
            }),
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            onWindowResize() {
                this.$store.dispatch('appInfo/checkDevice');
            },

            closeResumeModal() {
                this.$store.commit('me/CLOSE_RESUME_QUALITY_FEEDBACK_MODAL');
            },

            closeSidebar() {
                this.$_closeSidebar();
            },
        },

        head() {
            return this.head;
        },
    };

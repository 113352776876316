import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66a895aa = () => interopDefault(import('../pages/applypass/index.vue' /* webpackChunkName: "pages/applypass/index" */))
const _9dc14520 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _9a1e6208 = () => interopDefault(import('../pages/banjo.vue' /* webpackChunkName: "pages/banjo" */))
const _6ab7a540 = () => interopDefault(import('../pages/banjo-home.vue' /* webpackChunkName: "pages/banjo-home" */))
const _4d36bbd6 = () => interopDefault(import('../pages/browser-update.vue' /* webpackChunkName: "pages/browser-update" */))
const _4a3857de = () => interopDefault(import('../pages/company-article/index.vue' /* webpackChunkName: "pages/company-article/index" */))
const _7146e72c = () => interopDefault(import('../pages/company-search-results/index.vue' /* webpackChunkName: "pages/company-search-results/index" */))
const _495d74f4 = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _31fd8570 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _d9d44a26 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _3ec60127 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _0894b2a8 = () => interopDefault(import('../pages/job-match-feedback/index.vue' /* webpackChunkName: "pages/job-match-feedback/index" */))
const _2efc0d21 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _d1d1660a = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _52639afc = () => interopDefault(import('../pages/my-searches/index.vue' /* webpackChunkName: "pages/my-searches/index" */))
const _08b6a9f3 = () => interopDefault(import('../pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _f1902612 = () => interopDefault(import('../pages/one-auth.vue' /* webpackChunkName: "pages/one-auth" */))
const _37729091 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7ebb2206 = () => interopDefault(import('../pages/reset-email.vue' /* webpackChunkName: "pages/reset-email" */))
const _01e42ca1 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _2cb50104 = () => interopDefault(import('../pages/resume-writing.vue' /* webpackChunkName: "pages/resume-writing" */))
const _f7174d20 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _25989848 = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _1c90706e = () => interopDefault(import('../pages/set-password.vue' /* webpackChunkName: "pages/set-password" */))
const _df33232a = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _4a05ff59 = () => interopDefault(import('../pages/signout.vue' /* webpackChunkName: "pages/signout" */))
const _bd128166 = () => interopDefault(import('../pages/smart-login.vue' /* webpackChunkName: "pages/smart-login" */))
const _ba9fe508 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _d0ca6c26 = () => interopDefault(import('../pages/update-profile.vue' /* webpackChunkName: "pages/update-profile" */))
const _242247e2 = () => interopDefault(import('../pages/job-match-feedback/thankyou.vue' /* webpackChunkName: "pages/job-match-feedback/thankyou" */))
const _6c6f9e8d = () => interopDefault(import('../pages/notifications/saved-searches-freq/index.vue' /* webpackChunkName: "pages/notifications/saved-searches-freq/index" */))
const _52fc7b3a = () => interopDefault(import('../pages/notifications/subscriptions/index.vue' /* webpackChunkName: "pages/notifications/subscriptions/index" */))
const _248bed2f = () => interopDefault(import('../pages/static/privacy.vue' /* webpackChunkName: "pages/static/privacy" */))
const _9db51924 = () => interopDefault(import('../pages/static/terms.vue' /* webpackChunkName: "pages/static/terms" */))
const _f984be8a = () => interopDefault(import('../pages/unsubscribe/thankyou.vue' /* webpackChunkName: "pages/unsubscribe/thankyou" */))
const _36bb3634 = () => interopDefault(import('../pages/job/expired/_id.vue' /* webpackChunkName: "pages/job/expired/_id" */))
const _19230de6 = () => interopDefault(import('../pages/browse/_slug.vue' /* webpackChunkName: "pages/browse/_slug" */))
const _47a6d3ac = () => interopDefault(import('../pages/discover/_slug.vue' /* webpackChunkName: "pages/discover/_slug" */))
const _c7b0da6a = () => interopDefault(import('../pages/job-preview/_id.vue' /* webpackChunkName: "pages/job-preview/_id" */))
const _793ab542 = () => interopDefault(import('../pages/job-redirect/_id.vue' /* webpackChunkName: "pages/job-redirect/_id" */))
const _370f3b30 = () => interopDefault(import('../pages/jobview/_slug/_id.vue' /* webpackChunkName: "pages/jobview/_slug/_id" */))
const _095869d8 = () => interopDefault(import('../pages/discover/_slug' /* webpackChunkName: "" */))
const _73fa740e = () => interopDefault(import('../pages/company-article/index' /* webpackChunkName: "" */))
const _34701e17 = () => interopDefault(import('../pages/static/privacy' /* webpackChunkName: "" */))
const _f9e6c854 = () => interopDefault(import('../pages/static/terms' /* webpackChunkName: "" */))
const _7502ee0f = () => interopDefault(import('../pages/home' /* webpackChunkName: "" */))
const _63099e94 = () => interopDefault(import('../pages/company-search-results/index' /* webpackChunkName: "" */))
const _08435f48 = () => interopDefault(import('../pages/discover/index' /* webpackChunkName: "" */))
const _5232d2d8 = () => interopDefault(import('../pages/search' /* webpackChunkName: "" */))
const _d58f7978 = () => interopDefault(import('../pages/search-results/index' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/applypass",
    component: _66a895aa,
    name: "applypass"
  }, {
    path: "/auth",
    component: _9dc14520,
    name: "auth"
  }, {
    path: "/banjo",
    component: _9a1e6208,
    name: "banjo"
  }, {
    path: "/banjo-home",
    component: _6ab7a540,
    name: "banjo-home"
  }, {
    path: "/browser-update",
    component: _4d36bbd6,
    name: "browser-update"
  }, {
    path: "/company-article",
    component: _4a3857de,
    name: "company-article"
  }, {
    path: "/company-search-results",
    component: _7146e72c,
    name: "company-search-results"
  }, {
    path: "/discover",
    component: _495d74f4,
    name: "discover"
  }, {
    path: "/error",
    component: _31fd8570,
    name: "error"
  }, {
    path: "/forgot-password",
    component: _d9d44a26,
    name: "forgot-password"
  }, {
    path: "/home",
    component: _3ec60127,
    name: "home"
  }, {
    path: "/job-match-feedback",
    component: _0894b2a8,
    name: "job-match-feedback"
  }, {
    path: "/jobs",
    component: _2efc0d21,
    name: "jobs"
  }, {
    path: "/maintenance",
    component: _d1d1660a,
    name: "maintenance"
  }, {
    path: "/my-searches",
    component: _52639afc,
    name: "my-searches"
  }, {
    path: "/not-found",
    component: _08b6a9f3,
    name: "not-found"
  }, {
    path: "/one-auth",
    component: _f1902612,
    name: "one-auth"
  }, {
    path: "/profile",
    component: _37729091,
    name: "profile"
  }, {
    path: "/reset-email",
    component: _7ebb2206,
    name: "reset-email"
  }, {
    path: "/reset-password",
    component: _01e42ca1,
    name: "reset-password"
  }, {
    path: "/resume-writing",
    component: _2cb50104,
    name: "resume-writing"
  }, {
    path: "/search",
    component: _f7174d20,
    name: "search"
  }, {
    path: "/search-results",
    component: _25989848,
    name: "search-results"
  }, {
    path: "/set-password",
    component: _1c90706e,
    name: "set-password"
  }, {
    path: "/settings",
    component: _df33232a,
    name: "settings"
  }, {
    path: "/signout",
    component: _4a05ff59,
    name: "signout"
  }, {
    path: "/smart-login",
    component: _bd128166,
    name: "smart-login"
  }, {
    path: "/unsubscribe",
    component: _ba9fe508,
    name: "unsubscribe"
  }, {
    path: "/update-profile",
    component: _d0ca6c26,
    name: "update-profile"
  }, {
    path: "/job-match-feedback/thankyou",
    component: _242247e2,
    name: "job-match-feedback-thankyou"
  }, {
    path: "/notifications/saved-searches-freq",
    component: _6c6f9e8d,
    name: "notifications-saved-searches-freq"
  }, {
    path: "/notifications/subscriptions",
    component: _52fc7b3a,
    name: "notifications-subscriptions"
  }, {
    path: "/static/privacy",
    component: _248bed2f,
    name: "static-privacy"
  }, {
    path: "/static/terms",
    component: _9db51924,
    name: "static-terms"
  }, {
    path: "/unsubscribe/thankyou",
    component: _f984be8a,
    name: "unsubscribe-thankyou"
  }, {
    path: "/job/expired/:id?",
    component: _36bb3634,
    name: "job-expired-id"
  }, {
    path: "/browse/:slug?",
    component: _19230de6,
    name: "browse-slug"
  }, {
    path: "/discover/:slug",
    component: _47a6d3ac,
    name: "discover-slug"
  }, {
    path: "/job-preview/:id?",
    component: _c7b0da6a,
    name: "job-preview-id"
  }, {
    path: "/job-redirect/:id?",
    component: _793ab542,
    name: "job-redirect-id"
  }, {
    path: "/jobview/:slug?/:id?",
    component: _370f3b30,
    name: "jobview-slug-id"
  }, {
    path: "/my-activity/:slug",
    component: _095869d8,
    name: "my-activity-slug"
  }, {
    path: "/my-searches/:slug",
    component: _095869d8,
    name: "my-searches-slug"
  }, {
    path: "/jobs/:filters?/article/:article",
    component: _73fa740e,
    name: "company-article"
  }, {
    path: "/privacy",
    component: _34701e17
  }, {
    path: "/terms",
    component: _f9e6c854
  }, {
    path: "/",
    component: _7502ee0f,
    name: "home"
  }, {
    path: "/*/:brand(.*br_.*)",
    component: _63099e94,
    name: "company-search-results"
  }, {
    path: "/discover/",
    component: _08435f48,
    name: "discover"
  }, {
    path: "/search/",
    component: _5232d2d8,
    name: "search"
  }, {
    path: "/*",
    component: _d58f7978,
    name: "default-search-results"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
